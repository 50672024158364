@import url("https://fonts.googleapis.com/css2?family=Piazzolla:opsz,wght@8..30,100;8..30,200;8..30,300;8..30,400;8..30,500;8..30,600;8..30,700;8..30,800;8..30,900&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
  min-height: 100vh;
}
a,
a *,
a:after,
a:before {
  text-decoration: none;
  transition: 0.2s;
  box-sizing: border-box;
}
/* h1 {
  font-family: "Piazzolla", serif;
}
p {
  font-family: "Poppins", sans-serif;
} */
