.carousel_sec {
  background: linear-gradient(180deg, #266ba9 0, #1d5b8a 100%);
  text-align: center;
  padding: 30px 0;
}
.starswrap,
.floorstar {
  display: flex;
  gap: 2px;
}
.starswrap svg {
  color: #e64a19;
}
.starswrap {
  margin: 4px 0;
  margin-bottom: 8px;
}
.brand_review img {
  width: 90px;
}

.brand_review h3 {
  font-size: 24px;
  line-height: 29px;
  color: #37474f;
  font-weight: 600;
}

.user_review {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #37474f;
  margin: 0 0 10px;
}

.user_name {
  flex-grow: 1;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #37474f;
  margin: 0 0 20px;
}

.brand_logo img {
  width: 100px;
}

.button {
  padding: 11px 15px;
  width: 75%;
  display: inline-block;
  text-align: center;
  background: no-repeat padding-box #e64a19;
  box-shadow: 0 5px 5px #e64a191a;
  border-radius: 8px;
  font-size: 19px;
  color: #fafafa;
}

.button::after {
  content: "";
  width: 17px;
  height: 17px;
  background-position: center;
  background-size: contain;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class=%27a%27 d=%27M0,0H24V24H0Z%27/%3E%3Cpath class=%27b%27 d=%27M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z%27/%3E%3C/svg%3E");
  vertical-align: bottom;
  transition: 0.5s;
  transform: rotate(0);
}

.button:hover {
  background-color: #00acc1;
  cursor: pointer;
}

.button:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  background-position: 5px center;
}

.brand_review {
  background: #fff;
  text-align: left;
  padding: 25px;
  height: 100%;
  border-radius: 0 0 6px 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.brand_logo {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  background: #114064;
}

.carousel_card {
  opacity: 0.5;
  transition: opacity 0.4s ease-in;
  display: flex;
  flex-direction: row;
  width: 40%;
  padding: 0 1.7%;
  flex-shrink: 0;
}

#active_card {
  opacity: 1;
}

.carousel_sec h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 29px;
  font-family: Cabin, Arial, Sans-Serif;
  color: #fff;
}

.not_verified {
  margin-top: 8px;
  font-weight: 100;
  font-size: 15px;
  line-height: 29px;
  font-family: Cabin, Arial, Sans-Serif;
  margin-bottom: 48px;
  color: #fff;
}

.card_wrapper {
  display: flex;
  width: 100%;
  transform: translateX(-12%);
  transition: transform 0.4s ease-in-out;
}

.slider_controls {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 30px;
}

.slider_controls span {
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.4;
  cursor: pointer;
}

.slider_controls .active_control {
  opacity: 1;
}

@media only screen and (max-width: 1050px) {
  .carousel_card {
    opacity: 0.5;
    transition: opacity 0.4s ease-in;
    display: flex;
    flex-direction: column;
    width: 80%;
    flex-shrink: 0;
  }

  .card_wrapper {
    transform: translateX(-70%);
  }

  .button {
    width: 70%;
  }

  .brand_review {
    align-items: center;
    text-align: center;
  }

  .brand_logo {
    padding: 15px;
  }

  .brand_logo img {
    width: 80px;
  }
}

@media only screen and (max-width: 550px) {
  .button {
    width: 100%;
  }
}
