* {
  font-family: Arial, Helvetica, Sans-Serif;
}
.page_nav {
  height: 56px;
  background: #e0f5ff;
  z-index: -1;
  border-top: 2px solid #1d69a7;
  width: 100%;
}

#sticky_nav {
  position: fixed;
  top: 0;
  z-index: 1;
  transition-duration: 2.5s;
}

#sticky_link {
  position: fixed;
  z-index: 2;
  width: 100%;
  margin-top: -5px;
  transition-duration: 1s;
}

.page_nav_link ul li a {
  border: 1px solid #e1e1ec;
  height: 50px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  min-width: 200px;
  text-align: center;
  box-sizing: border-box;
  color: #1d69a7;
  z-index: 50;
}

.page_nav_link ul li a:hover {
  border-color: #1d69a7;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  text-decoration: underline;
}

.page_nav_link ul li .active {
  font-weight: 700;
  border-color: #1d69a7;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  text-decoration: underline;
}

.page_nav_link ul {
  display: flex;
  justify-content: space-between;
  width: 900px;
  margin: auto;
  margin-top: -25px;
}

.about_drop {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .page_nav_link {
    display: none;
  }

  .about_drop {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: -25px;
    border-radius: 20px;
    width: 292px;
    border: 1px solid#1d69a7;
    overflow: hidden;
    position: relative;
    z-index: 50;
  }

  #sticky_about_drop {
    position: fixed;
    margin-top: -10px;
  }

  #sticky_about_drop_div {
    position: fixed;
    margin-top: -35px;
    transition: 1s;
  }

  .drop_items {
    border-bottom: 1.5px solid #e1e1ec;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 290px;
    text-align: center;
    box-sizing: border-box;
    color: #1d69a7;
    z-index: 50;
  }

  .drop_items:hover {
    text-decoration: underline;
  }

  .drop_items p,
  .drop_head p {
    position: relative;
    right: -10px;
    top: -2px;
    border-right: 8px solid transparent;
    transform: rotate(225deg);
    border-top: 8px solid #1d69a7;
  }

  .drop_head {
    display: absolute;
    border: 1px solid #e1e1ec;
    height: 50px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 290px;
    text-align: center;
    color: #1d69a7;
    font-weight: 700;
    border-color: #1d69a7;
    box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  }

  .nav_wrapper2 {
    display: flex;
    justify-content: center;
  }
}
