/* * {
    font-family: Arial, Helvetica, Sans-Serif;
} */

.ossection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -10px;
}

.oscontainer {
  min-height: 190px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #333;
  font-size: 15px;
  line-height: 150%;
}

.oscontainer h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 25px;
  margin-top: 40px;
}

.oscontainer p {
 
  line-height: 175%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 510px) {
  .oscontainer img {
    margin-top: 15px;
  }

  .oscontainer {
    font-size: 17px;
    width: 88%;
    margin-bottom: 10px;
  }
}
