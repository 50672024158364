/* * {
  font-family: Arial, Helvetica, Sans-Serif;
} */

.we_are {
  background-color: #e0f5ff;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.wacontainer {
  margin-top: 5.55vw;
  margin-bottom: 5.55vw;
  width: 70%;
}

.waicon {
  height: 60px;
}

.wacontent h2 {
  font-size: 26px;
  line-height: 200%;
  font-weight: 700;
  word-break: break-word;
  color: #333333;
  margin-bottom: 16px;
}

.wacontent p {
  line-height: 150%;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
}

.wacontent p span {
  color: #005eab;
  font-weight: 700;
}

.wafirst {
  border-bottom: 2px solid #c4c4c4;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.wacontainer {
  display: flex;
}

.wacontent {
  display: flex;
}

.wafirst,
.wasecond {
  margin-left: 25px;
  margin-right: 30px;
}
.waconten{
     width: 2000px;
    
}

@media only screen and (max-width: 1060px) {
  .wacontainer,
  .wacontent {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .wacontainer {
    width: 95%;
  }

  .wafirst,
  .wasecond {
    max-width: 500px;
  }

  .wamap {
    margin-top: 30px;
  }

  .wafirst {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 690px) {
  .wamap {
    width: 330px;
  }
}

.vendor__btn {
  display: block;
  align-items: center;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .vendor__btn {
    min-width: 160px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 0;
  }
}
.vendor__btn_area {
  text-align: center;
}

.button {
  text-align: center;
  background: no-repeat padding-box #e64a19;
  box-shadow: 0 5px 5px #e64a191a;
  border-radius: 8px;
  width: 70%;
  display: inline-block;
  font-size: 19px;
  padding: 14px 15px;
  margin-bottom: 12px;
  color: #fafafa;
}
.button:hover {
  background-color: #00acc1;
}
@media (min-width: 768px) {
  .button {
    padding: 15px 22px;
    font-size: 16px;
    width: 100%;
    display: inline-block;
    text-align: center;
    background: no-repeat padding-box #e64a19;
    box-shadow: 0 5px 5px #e64a191a;
    border-radius: 8px;
    margin-bottom: 12px;
    color: #fafafa;
  }
  .button:hover {
    background-color: #00acc1;
  }
}

@media (min-width: 992px) {
  .button {
    font-size: 16px;
    text-align: center;
    background: no-repeat padding-box #e64a19;
    box-shadow: 0 5px 5px #e64a191a;
    border-radius: 8px;
    margin-bottom: 12px;
    color: #fafafa;
  }
  .button:hover {
    background-color: #00acc1;
  }
}

.button:after {
  content: "";
  width: 17px;
  height: 17px;
  background-position: center;
  background-size: contain;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  vertical-align: bottom;
  transition: 0.5s;
  transform: rotate(0);
}

.button:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  background-position: 5px center;
}
