.methodology_block_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.methodology_wrapper {
    display: flex;
   
    margin-bottom: 6.55vw;
    width:70%;
}

.methodology_img img {
    width: 400px;
}

.block_content_desc {
    margin-left: 50px;
}

.title_content_block {
    margin-bottom: 21px;
    font-size: 26px;
    line-height: 108%;
    font-weight: 700;
    word-break: break-word;
}

.block_content_desc p {
    margin-bottom: 25px;
    color: #333;
    line-height: 175%;
    font-size: 14.5px;
}




.methodology_card_img img {
    width: 50px;
}

.methodology_card_img {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

}

.methodology_cards .methodology_card {
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 8px rgb(0 105 191 / 20%);
    border-radius: 6px;

    width: 475px;
    margin-bottom: 18px;
    margin-right: 45px;
    padding-right: 10px;
    padding-left: 25px;

}

.methodology_card span {
    display: block;
    font-size: 26px;
    font-weight: 700;
    line-height: 108%;
    color: #005eab;
    margin-bottom: 5px;
}

.methodology_card p {

    font-size: 16px;
    color: #333;
    line-height: 120%;

}

.methodology_card_info {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

}

@media only screen and (max-width: 1060px) {
    .block_content_desc p {
        font-size: 16px;
    }

    .block_content_desc {
        margin-left: 0px;
    }

    .methodology_img img {
        width: 260px;
    }

    .methodology_img {
        margin-top: 20px;
        margin-bottom: 50px;
    }





    .block_content_desc p {
        font-size: 16px;
    }

    .methodology_wrapper {
        flex-direction: column;
        align-items: center;
        width:88%;
    }

    .block_content_desc {
        margin-left: 0px;
    }

    .methodology_img img {
        width: 260px;
    }

    .methodology_img {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .methodology_cards .methodology_card {
        width: 100%;
    }
}