*,
:after,
:before {
  box-sizing: border-box;
}
.vendors {
  display: block;
  flex-wrap: wrap;
  margin: 0 auto;
  font-family: Cabin, Arial, Sans-Serif;
  justify-content: center;
}
.available__os {
  margin-top: 20px;
}
.available__os svg {
  font-size: 19px;
  display: inline-block;
  margin-right: 20px;
  opacity: 0.5;
  color: #37474f;
}
@media (min-width: 768px) {
  .vendors {
    display: flex;
  }
}

.container {
  margin: 0 auto;
  padding: 0 25px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.vendor {
  background: #fff;
  border-radius: 6px;
  padding: 47px 12px 12px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 5px 0 30px #0000001a;
  /* box-shadow: 5px 0 30px #dd2C004D; */
}
@media (min-width: 768px) {
  .vendor {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 24px;
    padding: 60px 25px 27px;
    justify-content: space-between;
  }
}
.overlay-link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
a,
a *,
a:after,
a:before {
  text-decoration: none;
  transition: 0.2s;
}
.vendor_position {
  color: #fff;
  background: no-repeat padding-box #f57c00;
  padding: 10px 14px;
  height: 35px;
  position: absolute;
  width: 35x;
  letter-spacing: 0;
  z-index: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Cabin, Arial, Sans-Serif;
  top: 0;
  left: 0;
  border-radius: 6px 0;
}
#blue_position {
  background: no-repeat padding-box #00838f;
}
.vendor_ribbon {
  font-weight: 700;
  position: absolute;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 18px 0 24px;
  background-color: #ff9800;
  color: #fff;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: Cabin, Arial, Sans-Serif;
  z-index: 3;
  top: 0;
  left: 30px;
  border-radius: 6px 0;
}
#blue_ribbon {
  background-color: #00acc1;
}

.vendor_ribbon a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  vertical-align: center;
}
.dynamic__text_ribbon1__ribbon_desktop {
  display: none;
}
@media (min-width: 768px) {
  .dynamic__text_ribbon1__ribbon_desktop {
    display: inline-block;
  }
}
.dynamic__text_ribbon1__ribbon_mobile {
  display: inline-block;
}
@media (min-width: 768px) {
  .dynamic__text_ribbon1__ribbon_mobile {
    display: none;
  }
}

.seperator {
  display: none;
}
@media (min-width: 768px) {
  .seperator {
    margin: 0 24px;
    font-size: 20px;
    display: inline-block;
  }
}
.dynamic__text_ribbon2 {
  display: none;
}
@media (min-width: 768px) {
  .dynamic__text_ribbon2 {
    margin: 0 24px;
    font-size: 20px;
    display: inline-block;
  }
}

.dynamic__text_ribbon2 p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.vendor_logoSection {
  width: 100%;
}

@media (min-width: 768px) {
  .vendor_logoSection {
    max-width: 318px;
    width: 30%;
    flex-shrink: 0;
    color: #37474f;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: Cabin, Arial, Sans-Serif;
  }
}

.vendor__logoSection_logo {
  max-width: 180px;
  margin-bottom: 12px;
  height: 50px;
}
@media (min-width: 768px) {
  .vendor__logoSection_logo {
    max-width: 220px;
    margin-bottom: 12px;
    height: 55px;
  }
}

.vendor__logoSection_logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
img {
  height: auto;
}

.vendor__logoSection_description {
  display: none;
}
@media (min-width: 768px) {
  .vendor__logoSection_description {
    display: block;
    margin-left: 4px;
  }
}

ul {
  list-style: none;
}
@media (min-width: 768px) {
  .vendor__logoSection_listing {
    display: none;
  }
}
.vendor__logoSection_listing ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Cabin, Arial, Sans-Serif;
  margin-bottom: 6px;
  margin-left: 0px;
}
.vendor__logoSection_listing ul li img {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-position: center;

  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.vendor__logoSection_discount {
  font-size: 16px;
  font-weight: 700;
  color: #266ba9;
  margin-top: 10px;
  margin-bottom: 16px;
  margin-left: 4px;
}
.vendor__featured {
  display: none;
  width: 34%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .vendor__featured {
    display: block;
  }
}
.vendor__featured ul li {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Cabin, Arial, Sans-Serif;
  padding-left: 21px;
}
.vendor__featured ul li img {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  margin-bottom: 2px;
  background-position: center;

  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-left: -21px;
}

.vendor__score_btn {
  display: flex;
  width: auto;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 768px) {
  .vendor__score_btn {
    width: 150px;
    margin-left: auto;
    justify-content: center;
    flex-shrink: 0;
  }
}
@media (max-width: 992px) {
  .vendor__featured {
    width: auto;
  }
}
@media (min-width: 992px) {
  .vendor__score_btn {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    margin-left: 0;
    justify-content: center;
    flex-shrink: 0;
  }
}

.vendor__scoreSection_first {
  max-width: 64px;
  position: absolute;
  margin-right: 0;
  top: 38px;
  right: 14px;
}
@media (min-width: 415px) {
  .vendor__scoreSection_first {
    top: 12px;
  }
}

@media (min-width: 768px) {
  .vendor__scoreSection_first {
    top: 0;
    right: auto;
    max-width: 100%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .vendor__scoreSection_first {
    margin-right: 30px;
  }
}
svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

.donut {
  width: 64px;
  margin-top: -13px;
}
@media (min-width: 768px) {
  svg.donut {
    margin: 0 auto;
    display: block;
    width: 91px;
  }
}
.donut_segment {
  stroke: #ff9800;
}
.donut_segment_else {
  stroke: #00acc1;
}

@media (min-width: 768px) {
  .donut_segment {
    stroke: #ff9800;
  }
  .donut_segment_else {
    stroke: #00acc1;
  }
}

.donut text {
  fill: #37474f;
  font-size: 17px;
}

@media (min-width: 768px) {
  .donut text {
    font-size: 17px;
    fill: #37474f;
  }
}
.vendor__mark {
  display: none;
}
.vendor__mark_else {
  display: none;
}
@media (min-width: 768px) {
  .vendor__mark {
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    text-transform: uppercase;
    margin-top: 12px;
    display: block;
    color: #ff9800;
    font-weight: 700;
  }
  .vendor__mark_else {
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    text-transform: uppercase;
    margin-top: 12px;
    display: block;
    color: #37474f;
    font-weight: 500;
    text-align: center;
  }
}

.vendor__btn {
  display: block;
  align-items: center;
  width: 100%;
  z-index: 3;
}
@media (min-width: 768px) {
  .vendor__btn {
    min-width: 160px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 3;
  }
}
.vendor__btn_area {
  text-align: center;
}

.button {
  text-align: center;
  background: no-repeat padding-box #e64a19;
  box-shadow: 0 5px 5px #e64a191a;
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  font-size: 19px;
  padding: 14px 15px;
  margin-bottom: 12px;
  color: #fafafa;
}
.button:hover {
  background-color: #00acc1;
}
@media (min-width: 768px) {
  .button {
    padding: 11px 15px;
    font-size: 19px;
    width: auto;
    display: inline-block;
    text-align: center;
    background: no-repeat padding-box #e64a19;
    box-shadow: 0 5px 5px #e64a191a;
    border-radius: 8px;
    margin-bottom: 12px;
    color: #fafafa;
  }
  .button:hover {
    background-color: #00acc1;
  }
}

@media (min-width: 992px) {
  .button {
    font-size: 20px;
    text-align: center;
    background: no-repeat padding-box #e64a19;
    box-shadow: 0 5px 5px #e64a191a;
    border-radius: 8px;
    margin-bottom: 12px;
    color: #fafafa;
  }
  .button:hover {
    background-color: #00acc1;
  }
}

.button:after {
  content: "";
  width: 17px;
  height: 17px;
  background-position: center;
  background-size: contain;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  vertical-align: bottom;
  transition: 0.5s;
  transform: rotate(0);
}

.button:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  background-position: 5px center;
}

.vendor__btn_site_link {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #37474f;
  opacity: 0.8;
  text-decoration: underline;
  display: none;
}
@media (min-width: 768px) {
  .vendor__btn_site_link {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #37474f;
    opacity: 0.8;
    text-decoration: underline;
    width: 180px; /* Set the width of the container */
    overflow: hidden; /* Hide the overflowing content */
    white-space: nowrap; /* Prevent the text from wrapping */
    text-overflow: ellipsis;
  }
}
