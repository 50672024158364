.rate_wrap {
  float: right;
  margin: 20px 0 15px;
  width: 57%;
}
.rate_type .logo_wrap {
  display: flex;
  border-radius: 20px;
  width: 39px;
  height: 39px;
  background: #00acc1;
  text-align: center;
  padding: 3px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.rate_type li {
  display: flex;
  gap: 5px;
  align-items: center;
}
.rate_body {
  width: 680px;
}

.rate_type svg {
  width: 20px;
}
.rate_wrap p,
.rate_wrap span {
  line-height: 24px;
  font-size: 16px;
  font-family: Cabin, Arial, Sans-Serif;
}
/* .rate_wrap p :last-child {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; */
/* } */
.rate_wrap h1 {
  font-size: 32px;
  color: #fff;
  line-height: 32px;
  margin: 25px 0;
  font-family: Cabin, Arial, Sans-Serif;
}
.rate_container {
  background-image: url(../../assects/Home/rating_illustration.svg),
    linear-gradient(180deg, #266ba9 0, #1d5b8a 100%);
  background-repeat: no-repeat, no-repeat;
  background-origin: padding-box;
  font-family: Cabin, Arial, Sans-Serif;
  color: #fff;
  background-position: 3% bottom, 0 0;
  background-size: 38%, 100%;
  display: block;
  margin-top: 70px;
  height: 476px;
}
.rate_type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.rate_type li span {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rate_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.rate_body p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rate_body p:last-child {
  -webkit-line-clamp: 5;
}

@media only screen and (max-width: 1200px) {
  .rate_container {
    background-position: -61% bottom, 0 0;
    background-size: 62%, 100%;
    height: 524px;
  }
  .rate_body {
    width: 617px;
  }
  .rate_wrap {
    width: 61%;
  }
}
@media only screen and (max-width: 1022px) {
  .rate_container {
    background: linear-gradient(180deg, #266ba9 0, #1d5b8a 100%);
    font-family: Cabin, Arial, Sans-Serif;
    color: #fff;
    display: flex;
    margin-top: 70px;
    height: auto;
    padding-bottom: 20px;
  }
  .rate_body {
    width: 100%;
  }
  .rate_wrap {
    width: 750px;
    margin: 20px auto;
  }
}
@media only screen and (max-width: 768px) {
  .rate_container {
    display: none;
  }
}
