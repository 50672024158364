.value_block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.block_content_wrapper {
    display: flex;
    margin-top: 6.55vw;
    margin-bottom: 6.55vw;
    width:70%;
}

.value_img img {
    width: 400px;
}

.block_desc {
    margin-left: 50px;
}

.block_content_title {
    margin-bottom: 21px;
    font-size: 26px;
    line-height: 108%;
    font-weight: 700;
    word-break: break-word;
}

.block_desc p {
    margin-bottom: 25px;
    color: #333;
    line-height: 175%;
    font-size: 14.5px;
}

@media only screen and (max-width: 1060px) {
    .block_desc p {
        font-size: 16px;
    }

    .block_content_wrapper {
        flex-direction: column;
        align-items: center;
        width:88%;
    }

    .block_desc {
        margin-left: 0px;
    }

    .value_img img {
        width: 260px;
    }

    .value_img {
        margin-top: 20px;
        margin-bottom: 50px;
    }
}