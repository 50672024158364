input[type="text"],
textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #e1e1ec;
  border-radius: 6px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
textarea {
  height: 150px;
  resize: none;
}
textarea:focus,
input[type="text"]:focus {
  outline: none;
}

input[type="text"]::placeholder,
textarea::placeholder {
  font-size: 19px;
  color: #e1e1ec;
}

input[type="submit"] {
  width: 80%;
  margin-left: 9.75%;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  margin-top: 38px;
  background: linear-gradient(180deg, #44a5f4 0, #027fe6 100%);
  border-radius: 6px;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.submitBtn:hover {
  background: #0281e9;
}

.form_container {
  border-radius: 5px;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0.78847px 1.78847px 7.15389px rgb(0 105 191 / 20%);
  border-radius: 6.30996px;
  width: 110%;
  display: flex;
}
.form_container label {
  font-weight: 700;
  color: #37474f;
  font-size: 20px;
  line-height: 35px;
}

.contact_heading,
.contact_content {
  display: flex;
  justify-content: center;
}
.contact_content {
  background-color: #f3f6f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.headingwrapper {
  width: 70%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 35px;
}
.contact_wrapper {
  width: 70%;
  display: flex;
  gap: 65px;
}
.headingwrapper h1 {
  margin-bottom: 24px;
  font-size: 45px;
  line-height: 52px;
  font-size: 45px;
  line-height: 52px;
  font-size: 45px;
  line-height: 52px;
}
.headingwrapper p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-family: Arial;
  font-style: normal;
}
.social_card {
  background-color: #fff;
  box-shadow: 0.78847px 1.78847px 7.15389px rgb(0 105 191 / 20%);
  display: flex;
  padding: 30px;
  border-radius: 6px;
  gap: 25px;
  margin-top: 20px;
  height: 275px;
}
.div1_in_card img,
.div1_in_card a {
  width: 40px;
}
.div1_in_card {
  margin-bottom: 10px;
}
.div2_in_card img,
.div2_in_card a {
  width: 34px;
  margin-right: 7px;
}
.div2_in_card a {
  display: inline-block;
}
.div1_in_card,
.div2_in_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.social_card h5 {
  font-size: 20px;
  line-height: 135%;
  font-weight: 700;
  color: #333;
  font-family: Arial;
  font-style: normal;
}
.dropline h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 175%;
}
.dropline p {
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  color: #898989;
}
.dropline {
  margin-top: 110px;
}
.contact_us {
  margin-bottom: 120px;
}

.div2_in_card img:hover {
  width: 36px;
}

@media only screen and (max-width: 1065px) {
  .headingwrapper h1 {
    font-size: 40px;
  }
  .contact_wrapper {
    flex-direction: column;
    align-items: center;
    width: 88%;
  }
  .headingwrapper {
    width: 88%;
  }

  .form_container {
    width: 100%;
    margin-top: -80px;
  }
  .dropline {
    margin-top: 0px;
  }
  .contact_heading {
    margin-bottom: 25px;
  }
  .contact_wrapper {
    margin-bottom: -80px;
  }
  input[type="text"]::placeholder,
  textarea::placeholder {
    font-size: 18px;
  }
  .form_container label {
    font-size: 18px;
  }
  .form_container {
    padding: 40px 20px 40px 20px;
  }
  input[type="submit"] {
    width: 100%;
    margin-left: 0;
  }
}
