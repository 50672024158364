.faqs {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .faqs {
    padding: 30px 20px;
  }
}

.container {
  margin: 0 auto;
  padding: 0 25px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
    padding: 0;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
    padding: 0;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    padding: 0;
    margin: 0 auto;
  }
}

.faqs__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 29px;
  margin: 68px 0 48px;
  text-align: left;
}
@media (min-width: 768px) {
  .faqs__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 29px;
    margin: 68px 0 48px;
    text-align: left;
  }
}
.faqs__list {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .faqs__list {
    margin-top: 30px;
  }
}

.faq {
  box-shadow: 5px 0 15px #0000001a;
  padding: 25px 10px 25px 40px;
  border-radius: 6px;
  margin-bottom: 25px;
}

.faqs details > summary {
  list-style: none;
}

.faq__title {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  display: block;
  position: relative;
  cursor: pointer;
}
.faq__title::before {
  content: "";
  position: absolute;
  top: calc(50% - 8px);
  left: -25px;
  width: 0;
  height: 0;
  border-top: 10px solid #37474f;
  border-right: 10px solid transparent;
  transform: rotate(225deg);
  transition: transform 0.25s;
}
.open .faq__title::before {
  top: calc(50% - 6px);
  transform: rotate(135deg);
}

.faq__text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
}
p {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .faq__title {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    display: block;
    position: relative;
    cursor: pointer;
  }
  .faqs__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 29px;
    margin: 38px 0 28px;
    text-align: left;
  }
}
