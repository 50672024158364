@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Piazzolla:opsz,wght@8..30,100;8..30,200;8..30,300;8..30,400;8..30,500;8..30,600;8..30,700;8..30,800;8..30,900&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap");
body {
  width: 100%;
  line-height: 1;
  color: #37474f;
  background: #fff;
  font-size: 1rem;
  direction: ltr;
  top:0 !important;
}
* {
  font-family: Cabin, Arial, Sans-Serif;
  list-style: none;
  text-decoration: none;
}
b,
strong {
  font-weight: 700;
}
.preloader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
