#top_three {
  background: linear-gradient(
      180deg,
      rgba(55, 71, 79, 0.0509804) 0,
      rgba(205, 192, 192, 0.121569) 100%
    )
    no-repeat padding-box;
  padding-top: 30px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  #top_three {
    background: linear-gradient(
        180deg,
        rgba(55, 71, 79, 0.0509804) 0,
        rgba(205, 192, 192, 0.121569) 100%
      )
      no-repeat padding-box;
    padding-top: 30px;
    padding-bottom: 70px;
  }
}
.top_three {
  background: linear-gradient(
      180deg,
      rgba(55, 71, 79, 0.0509804) 0,
      rgba(205, 192, 192, 0.121569) 100%
    )
    no-repeat padding-box;
  padding-top: 30px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .top_three {
    background: linear-gradient(
        180deg,
        rgba(55, 71, 79, 0.0509804) 0,
        rgba(205, 192, 192, 0.121569) 100%
      )
      no-repeat padding-box;
    padding-top: 30px;
    padding-bottom: 70px;
  }
}
.container {
  margin: 0 auto;
  padding: 0 25px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
    padding: 0;
  }
}

#top_three .section_title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  margin: 25px 0;
}
@media (min-width: 768px) {
  #top_three .section_title {
    text-align: left;
    font-size: 32px;
    line-height: 32px;
    margin: 25px 0;
  }
}
.top_three__tabs {
  display: flex;
}
@media (min-width: 768px) {
  .top_three__tabs {
    display: none;
  }
}
.top_three__tab {
  width: 33.3%;
  display: flex;
  align-content: center;
  justify-items: center;
  /* background: #fff; */
  background: #e8e8e8;
  list-style: none;
  padding: 0;
  margin: 0;
}

.top_three__tab_js_active {
  width: 33.3%;
  display: flex;
  align-content: center;
  justify-items: center;
  background: #fff;

  list-style: none;
  padding: 0;
  margin: 0;
}

.top_three__tab a {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.top_three__tab_js_active a {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: center;
}

/* img {
        max-width: 100%;
        height: auto;
    } */
.top_three__tab a img {
  max-height: 40px;
  width: auto;
}
.top_three__tab_js_active a img {
  max-height: 40px;
  width: auto;
}

.top_three__list {
  margin-top: 0;
}
@media (min-width: 768px) {
  .top_three__list {
    margin-top: 45px;
  }
}

.top_three__item_js_active {
  display: block;
  position: relative;
  padding: 12pt 24pt 24pt;
  background: #fff;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) {
  .top_three__item_js_active {
    padding: 24px;
    box-shadow: 5px 10px 15px #0000001a;
    border-radius: 6px;
    display: flex !important;
    position: relative;

    background: #fff;
    margin-bottom: 30px;

    justify-content: space-between;
    align-items: center;
  }
}
.top_three__item {
  position: relative;
  padding: 12pt 24pt 24pt;
  background: #fff;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  display: none;
}
@media (min-width: 768px) {
  .top_three__item {
    padding: 24px;
    box-shadow: 5px 10px 15px #0000001a;
    border-radius: 6px;
    display: flex !important;
    position: relative;

    background: #fff;
    margin-bottom: 30px;

    justify-content: space-between;
    align-items: center;
  }
}
.overlay_link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.category__item_top_badge {
  display: none;
}
@media (min-width: 768px) {
  .category__item_top_badge {
    display: block;
    background: #00acc1;
    color: #fff;
    position: absolute;
    top: 0;
    width: 39px;
    height: 39px;
    line-height: 33px;
    padding: 0 8px;
    border-radius: 0 0 39px;
    left: 0;
  }
}

.top_three__item_desc_wrap {
  display: none;
  width: 45%;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .top_three__item_desc_wrap {
    display: block;
    width: 45%;
    padding-right: 15px;
  }
}
@media (min-width: 768px) {
  .top_three__item_desc_wrap {
    display: block;
    width: 45%;
    padding-right: 25px;
  }
}
.top_three__item_logo {
  margin-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
}
.top_three__item_logo img {
  max-height: 52px;
  width: 100%;
  object-fit: contain;
}
.top_three__item_desc_wrap p {
  line-height: 24px;
}

.top_three__item_desc_notice {
  color: #266ba9;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 15px;
}
.top_three__item_subtitle {
  display: block;
  font-weight: 500;
  font-size: 18px;
  margin: 15px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .top_three__item_subtitle {
    display: none;
  }
}
@media (min-width: 768px) {
  .top_three__item_features {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .top_three__item_features {
    width: 38%;
  }
}
.top_three__item_feature {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .top_three__item_feature {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    line-height: 16px;
  }
}
.top_three__item_feature_label {
  color: #266ba9;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .top_three__item_feature_label {
    width: 45%;
    color: #266ba9;
    display: flex;
    align-items: center;
  }
}
.top_three__item_feature_label .fa {
  background: #266ba9;
  width: 24px;
  height: 24px;
  border-radius: 20%;
  margin-right: 13px;
  line-height: 24px;
  color: #fff;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 12px;
}
small {
  font-size: 15px;
  font-weight: 500;
}
.top_three__item_feature_value {
  padding-left: 8px;
}
@media (min-width: 768px) {
  .top_three__item_feature_value {
    padding-left: 8px;
    width: 55%;
  }
}

@media (min-width: 992px) {
  .top_three__item_feature_label small br {
    display: none;
  }
}

.top_three__item_features_cta_wrap {
  display: none;
  z-index: 4;
}
@media (min-width: 768px) {
  .top_three__item_features_cta_wrap {
    display: block;
    z-index: 4;
  }
}
/* @media (min-width: 768px) and (max-width: 992px) {
        .top_three__item_features_cta_wrap {
            display: block;
        }
    } */
@media (min-width: 992px) {
  .top_three__item_features_cta_wrap {
    display: none;
  }
}

.top_three__item_best_for {
  margin-top: 25px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .top_three__item_best_for {
    display: none;
  }
}
.top_three__item_price {
  font-size: 24px;
  font-weight: 500;
  color: #266ba9;
}
.top_three__item_price_wrap {
  text-align: center;
  margin-bottom: 20px;
  display: block;
}
@media (min-width: 768px) {
  .top_three__item_price_wrap {
    display: none;
  }
}

.top_three__item_price_range {
  color: #37474f;
  font-size: 14px;
  opacity: 0.8;
}
@media (min-width: 768px) {
  .top_three__item_price_range {
    font-size: 16px;
    color: #37474f;
    opacity: 0.8;
  }
}

.top_three__item_cta_wrap {
  text-align: center;
  z-index: 4;
}
@media (min-width: 768px) {
  .top_three__item_cta_wrap {
    width: 15%;
    text-align: center;
    z-index: 4;
  }
  .top_three__item_cta_wrap a:last-child {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #37474f;
    opacity: 0.8;
    text-decoration: underline;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/* .wraplinkText {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto;
} */
@media (min-width: 768px) and (max-width: 992px) {
  .top_three__item_cta_wrap {
    display: none;
    text-align: center;
    z-index: 4;
  }
}

.button {
  text-align: center;
  background: no-repeat padding-box #e64a19;
  box-shadow: 0 5px 5px #e64a191a;
  border-radius: 8px;
  width: 100%;
  display: inline-block;
  font-size: 19px;
  padding: 14px 15px;
  margin-bottom: 12px;
  color: #fafafa;
}
@media (min-width: 768px) {
  .button {
    padding: 11px 15px;
    width: auto;
    display: inline-block;

    text-align: center;
    background: no-repeat padding-box #e64a19;
    box-shadow: 0 5px 5px #e64a191a;
    border-radius: 8px;

    font-size: 19px;

    margin-bottom: 12px;
    color: #fafafa;
  }
}
.button:after {
  content: "";
  width: 17px;
  height: 17px;
  background-position: center;
  background-size: contain;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  vertical-align: bottom;
  transition: 0.5s;
  transform: rotate(0);
}
.button:hover {
  background-color: #00acc1;
}
.button:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M10.02,6,8.61,7.41,13.19,12,8.61,16.59,10.02,18l6-6Z'/%3E%3C/svg%3E");
  background-position: 5px center;
}
.category__item_features {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  word-break: break-word;
}
@media (min-width: 768px) {
  .category__item_features {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    word-break: break-word;
  }
}

@media (min-width: 992px) {
  .category__item_features {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    word-break: normal;
  }
}

.category__item_feature {
  text-align: center;
  margin: 0 12px;
}

.category__item_feature_label {
  display: inline-block;
  border: 3px solid #37474f80;
  position: relative;
  border-radius: 100%;
  width: 58px;
  height: 58px;
  color: #00acc1;
  text-align: center;
  line-height: 51px;
}

.category__item_feature_check {
  border-radius: 100%;
  border: 2px solid #37474f80;
  width: 16px;
  height: 16px;
  font-size: 9px;
  text-align: center;
  line-height: 14px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 3px;
  left: calc(50% - (16px / 2));
  display: block;
  background: #fff;
  color: #00acc1;
}

@media (min-width: 768px) {
  .category__item_cta_wrapper {
    margin: 20px auto 0;
    z-index: 3;
    position: relative;
  }
}

@media (min-width: 992px) {
  .category__item_cta_wrapper {
    position: relative;
    margin: 20px auto 0;
    z-index: 3;
    width: fit-content;
  }
}
